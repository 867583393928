import React from 'react'

const Title = () => {
    return (
        <div className="contentName">
            <p>Hi! I´m Andrés</p>
            <p>Vergara -</p>
            <div className="sub">
                <svg id="webText" className="webText" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1035.81055 116.79932">
                    <path className ="cls-1 w"
                        d="M594.896,271.62646,569.18115,372.68457H554.76123L538.05859,321.855a114.2,114.2,0,0,1-4.20556-20.42773h-.48047A117.86054,117.86054,0,0,1,529.1665,321.855l-16.70263,50.82959H498.04443L472.3291,271.62646h16.34229L501.34863,326.001q2.52393,10.875,4.92676,25.11377h.36035a216.92256,216.92256,0,0,1,6.18848-25.11377l13.63867-46.3833h14.29932l13.63867,46.3833a237.16771,237.16771,0,0,1,6.12842,25.11377h.42041q2.283-14.17823,4.8667-25.11377l12.67724-54.37452Z"
                        transform="translate(-472.3291 -266.09912)" />
                    <path className ="cls-1 e"
                        d="M671.9209,338.13721H617.78711q.41967,9.553,6.0083,15.501a22.67544,22.67544,0,0,0,17.12305,7.02979q12.25707,0,21.68945-11.47559l9.61328,9.7334q-11.83667,14.89966-32.80469,14.8999-17.18408,0-27.69775-10.99463-10.03418-10.574-10.03369-27.27734,0-13.57764,6.54883-23.6123a33.56435,33.56435,0,0,1,15.56152-12.91748,36.83271,36.83271,0,0,1,14.23926-2.76368q12.07617,0,20.60791,6.729,8.83227,6.8496,11.89648,19.166a48.91832,48.91832,0,0,1,1.38184,11.77637Zm-16.102-11.71582a21.31091,21.31091,0,0,0-3.24463-10.334q-5.22657-7.62963-15.02-7.63037a17.64442,17.64442,0,0,0-14.41992,7.08936,21.54638,21.54638,0,0,0-4.32569,10.875Z"
                        transform="translate(-472.3291 -266.09912)" />
                    <path className ="cls-1 b"
                        d="M690.96729,266.09912h16.10156v41.57666q8.17017-10.93432,24.03271-10.93506,16.3418,0,25.77539,13.27832,7.26857,10.21509,7.26953,25.29444,0,16.28247-8.41113,26.67627-9.3728,11.53564-24.99414,11.53564-14.84033,0-25.0542-11.83594l-1.68213,9.97364H690.96729ZM707.06885,346.669a24.29013,24.29013,0,0,0,6.96972,9.61328,20.68346,20.68346,0,0,0,13.21778,4.686,17.72729,17.72729,0,0,0,15.68164-8.35107q4.385-6.66943,4.38574-17.06348,0-9.6123-3.78516-16.34228-5.58763-10.03272-16.4624-10.0337-11.41553,0-20.00732,12.437Z"
                        transform="translate(-472.3291 -266.09912)" />
                    <path className ="cls-1 d"
                        d="M873.67578,266.09912h16.10254v105.564H874.75781v-9.97364a24.97524,24.97524,0,0,1-6.48926,6.48877,32.00357,32.00357,0,0,1-18.32519,5.34717q-16.46191,0-25.89453-12.7373-7.45167-10.15284-7.4502-25.47461,0-12.676,5.22657-22.0503a31.25956,31.25956,0,0,1,27.69824-16.52246q15.91992,0,24.15234,10.93506Zm0,55.51563q-8.77295-12.43653-20.00683-12.437-10.39454,0-16.042,9.313-4.20556,6.90967-4.20605,17.063,0,8.95313,3.24512,15.14063,5.34667,10.27441,16.88281,10.27392a19.6544,19.6544,0,0,0,14.17969-6.0083,27.39254,27.39254,0,0,0,5.94726-9.01221Z"
                        transform="translate(-472.3291 -266.09912)" />
                    <path className ="cls-1 e2"
                        d="M977.49707,338.13721H923.36328q.4204,9.553,6.00879,15.501a22.67618,22.67618,0,0,0,17.12305,7.02979q12.25635,0,21.68945-11.47559l9.61328,9.7334q-11.8374,14.89966-32.80469,14.8999-17.18408,0-27.69726-10.99463-10.03565-10.574-10.03418-27.27734,0-13.57764,6.54883-23.6123a33.562,33.562,0,0,1,15.56152-12.91748,36.83449,36.83449,0,0,1,14.23926-2.76368q12.07617,0,20.6084,6.729,8.83154,6.8496,11.8955,19.166a48.91832,48.91832,0,0,1,1.38184,11.77637Zm-16.10156-11.71582a21.32105,21.32105,0,0,0-3.24414-10.334q-5.228-7.62963-15.02051-7.63037a17.64442,17.64442,0,0,0-14.41992,7.08936,21.5467,21.5467,0,0,0-4.32617,10.875Z"
                        transform="translate(-472.3291 -266.09912)" />
                    <path className ="cls-1 v"
                        d="M985.96875,298.72363h17.60449l14.23926,39.41358a133.35871,133.35871,0,0,1,4.44629,14.84033h.48047a134.515,134.515,0,0,1,4.44531-14.84033l14.29981-39.41358h17.60449L1028.80762,372.564H1016.25Z"
                        transform="translate(-472.3291 -266.09912)" />
                    <path className ="cls-1 e3"
                        d="M1136.2334,338.13721h-54.13379q.42041,9.553,6.00879,15.501a22.67618,22.67618,0,0,0,17.123,7.02979q12.25634,0,21.68945-11.47559l9.61328,9.7334q-11.8374,14.89966-32.80469,14.8999-17.18407,0-27.69726-10.99463-10.03565-10.574-10.03418-27.27734,0-13.57764,6.54883-23.6123a33.562,33.562,0,0,1,15.56152-12.91748,36.83449,36.83449,0,0,1,14.23926-2.76368q12.07617,0,20.60839,6.729,8.83155,6.8496,11.89551,19.166a48.91832,48.91832,0,0,1,1.38184,11.77637Zm-16.10156-11.71582a21.32105,21.32105,0,0,0-3.24414-10.334q-5.228-7.62963-15.02051-7.63037a17.6444,17.6444,0,0,0-14.41992,7.08936,21.54671,21.54671,0,0,0-4.32618,10.875Z"
                        transform="translate(-472.3291 -266.09912)" />
                    <path className ="cls-1 l"
                        d="M1157.50293,266.09912h16.22168v89.82227a2.49535,2.49535,0,0,0,2.82422,2.82373h9.97363l-1.74219,12.55713H1171.1416a16.00874,16.00874,0,0,1-7.87109-1.74219q-5.76857-3.123-5.76758-11.17529Z"
                        transform="translate(-472.3291 -266.09912)" />
                    <path className ="cls-1 o"
                        d="M1231.82422,296.38037q16.522,0,27.27637,11.23535,10.21435,10.63477,10.21386,27.45752,0,16.10231-9.13183,26.55615-10.69483,12.13551-28.53907,12.13624-18.38525,0-29.0791-12.85743-8.53271-10.333-8.53222-25.835,0-21.56909,16.042-32.32422A38.13054,38.13054,0,0,1,1231.82422,296.38037Zm-.24024,12.3169a18.69932,18.69932,0,0,0-12.61718,4.86669q-8.35254,7.21-8.35157,21.50928a34.119,34.119,0,0,0,2.043,12.13623,22.62891,22.62891,0,0,0,5.64746,8.5918,18.9814,18.9814,0,0,0,13.458,5.167,18.652,18.652,0,0,0,16.40235-8.832q4.56591-6.90821,4.5664-17.12305,0-11.83593-6.1289-19.166A18.8253,18.8253,0,0,0,1231.584,308.69727Z"
                        transform="translate(-472.3291 -266.09912)" />
                    <path className ="cls-1 p"
                        d="M1300.61719,298.72363v9.85352q9.97266-11.83595,25.05469-11.83643,15.14063,0,24.39257,10.09375,8.833,9.553,8.832,25.71533,0,13.63917-6.30859,22.83106-9.07177,13.218-26.67578,13.21777-15.98291,0-24.09277-10.93457v25.23438H1285.7168V298.72363Zm1.20215,45.00147q8.65135,12.43652,20.1875,12.43652,9.19189,0,14.53906-6.06787,5.52832-6.24829,5.52832-17.72412,0-10.3938-4.627-16.34229-5.28663-6.72876-15.44043-6.72949a20.45782,20.45782,0,0,0-13.03809,4.56641,24.12985,24.12985,0,0,0-7.14941,9.7334Z"
                        transform="translate(-472.3291 -266.09912)" />
                    <path className ="cls-1 e4"
                        d="M1441.38867,338.13721h-54.13379q.42042,9.553,6.00879,15.501a22.67618,22.67618,0,0,0,17.123,7.02979q12.25635,0,21.68945-11.47559l9.61328,9.7334q-11.8374,14.89966-32.80468,14.8999-17.18409,0-27.69727-10.99463-10.03565-10.574-10.03418-27.27734,0-13.57764,6.54883-23.6123a33.562,33.562,0,0,1,15.56152-12.91748,36.83449,36.83449,0,0,1,14.23926-2.76368q12.07617,0,20.6084,6.729,8.83154,6.8496,11.89551,19.166a48.91882,48.91882,0,0,1,1.38183,11.77637Zm-16.10156-11.71582a21.321,21.321,0,0,0-3.24414-10.334q-5.228-7.62963-15.02051-7.63037a17.64442,17.64442,0,0,0-14.41992,7.08936,21.5467,21.5467,0,0,0-4.32617,10.875Z"
                        transform="translate(-472.3291 -266.09912)" />
                    <path className="cls-1 r"
                        d="M1473.833,320.47314h.48047q7.99073-23.8513,24.57422-23.85253a23.72526,23.72526,0,0,1,9.252,1.86279l-4.14551,15.14062a21.37416,21.37416,0,0,0-6.90918-1.1416q-9.19336,0-15.32129,9.25244-4.08544,6.06958-5.707,15.62159v33.9458h-15.98242V298.72363h15.14062Z"
                        transform="translate(-472.3291 -266.09912)" />
                </svg>
            </div>
        </div>
    )
}

export default Title
