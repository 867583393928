import React from 'react';

const Footer = () =>{

    return(
        <footer className ="footer">
            <p className="politics"> Developed and designed by Andrés Vergara. ©2021. All rights reserved.</p>
        </footer>
    );
};

export default Footer;